export const state = () => ({
  errorModal: null,
});
export const mutations = {
  openErrorModal(state, errorMessage) {
    state.errorModal = errorMessage;
  },
  closeErrorModal(state) {
    state.errorModal = null;
  },
};
export const actions = {};
export const getters = {
  errorModalVisible(state) {
    return !!state.errorModal;
  },
  errorModalProps(state) {
    return {
      errorMessage: state.errorModal,
    };
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
