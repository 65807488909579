import { Model } from "@vuex-orm/core";

export default class EventAddonModel extends Model {
  static entity = "event_addons";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      description: this.string(""),
      price: this.number(null),
      currency: this.string(""),
    };
  }
}
