import colorScheme from "./scheme";

export function createStyleElement(id, parentNode) {
  const element = document.createElement("style");
  element.id = id;
  parentNode.appendChild(element);
  return element;
}

export function generateTheme(colors, namespace) {
  const def = createDefFunction(colors);

  // eslint-disable-next-line prettier/prettier
  const cssVariables = Object.keys(colorScheme).map(key => `${key}: ${colorScheme[key](def, colors)};`);

  return `:host, :root, [theme="${namespace}"] {\n${cssVariables.join("\n")}\n}`;
}

function createDefFunction(colors) {
  return function (...values) {
    const defaultValue = values.pop();
    for (const key of values) {
      if (colors[key]) {
        return colors[key];
      }
    }

    return defaultValue;
  };
}
