<template>
  <div class="checkbox-container unselectable" style="cursor: pointer" @click.stop="onClick" :class="classes" :style="containerStyle">
    <tick-icon class="checkbox-icon svg-icon" :class="classes"></tick-icon>
  </div>
</template>

<script>
import TickIcon from "@/assets/icon_tick.svg";

export default {
  name: "Checkbox",

  components: { TickIcon },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "18px",
    },
  },

  methods: {
    onClick() {
      this.$emit("input", !this.value);
    },
  },

  computed: {
    containerStyle() {
      return {
        width: this.size,
        minWidth: this.size,
        height: this.size,
        minHeight: this.size,
      };
    },

    classes() {
      return {
        selected: this.value,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
