export const FormBuilderConstants = Object.freeze({
  TITLE: "title",
  SUBTITLE: "subtitle",
  INCLUDE_TERMS_AND_GDPR: "include_terms_and_gdpr",
  SOCIAL_LOGIN_VISIBLE: "social_login_visible",
  APPLE_CLIENT_ID: "apple_client_id",
  FACEBOOK_APP_ID: "facebook_app_id",
  TRANSLATIONS_NAMESPACE: "custom_forms",
  SMAKKI_TRANSLATIONS_NAMESPACE: "translations_namespace",
  TRANSLATIONS: "translations",
  FIELDS: "fields",
  DISABLED_WHEN: "disabled_when",

  ROOT_TRANSLATION_KEYS: {
    TITLE_TRANSLATION_KEY: "form_custom_title_translation_key",
    SUBTITLE_TRANSLATION_KEY: "form_custom_subtitle_translation_key",
  },

  DISABLED_WHEN_PROPERTIES: {
    FIELD_ID: "field_id",
    VALUE: "value",
  },

  FIELD_PROPERTIES: {
    ID: "id",
    ORDER: "order",
    TYPE: "type",
    REQUIRED: "required",
    RULES: "rules",
    LABELS: "labels",
    DISABLED: "disabled",
    // Country input
    MULTI_SELECT: "multi_select",
    //Dropdown input
    TEXT_FIELD_OPTION_INDEX: "text_field_option_index",
    DROPDOWN_PLACEHOLDER: "dropdown_placeholder",
    DROPDOWN_OTHER_INPUT_LABEL: "dropdown_other_input_label",
    SELECTED_INDEX: "selected_index",
    //Information 'input'
    COLLAPSED: "collapsed",
    COLLAPSED_BUTTON_TEXT: "collapsed_button_text",
    HAS_HTML: "has_html",
    //Information / checkbox input
    SUBSTRINGS: "substrings",
    COLLAPSED_TEXT_LABELS: "collapsed_text_labels",
    COLLAPSED_TEXT_SUBSTRINGS: "collapsed_text_substrings",
    //Dropdown / radio input
    VALUES: "values"
  },

  RULE_PROPERTIES: {
    TYPE: "type",
    FIELD_ID: "field_id",
    INCLUDE: "include",
    EXCLUDE: "exclude",
  },

  SUBSTRINGS_PROPERTIES: {
    STRINGS: "strings",
    LINKS: "links",
  },

  FIELD_TYPES: {
    REGULAR_REGISTER_FORM_FIELDS: "regular_register_form_fields",
    TEXT: "text",
    TEXT_AREA: "text_area",
    PHONE_NUMBER: "phone_number",
    DROPDOWN: "dropdown",
    CHECKBOX: "checkbox",
    COUNTRY: "country",
    DATE: "date",
    EMAIL: "email",
    RADIO: "radio",
    INFORMATION: "information",
  },

  VALIDATIONS: {
    REQUIRED: "required",
    EMAIL: "email",
    PHONE_NUMBER_REQUIRED: "phone_number_required",
    PHONE_NUMBER_OPTIONAL: "phone_number_optional",
  },

  RULE_TYPES: {
    LOCALE: "locale",
    FIELD_VALUE: "field_value_text",
    FIELD_VALUE_INDEX: "field_value_index",
    FIELD_NOT_EMPTY: "field_not_empty",
    FIELD_BOOLEAN_VALUE: "field_boolean_value",
    FIELD_COUNTRY: "field_country",
    FIELD_DATE: "field_date",
  },

  REGISTER_FIELDS: {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    PASSWORD: "password",
    REPEAT_PASSWORD: "repeatPassword",
    TERMS: "terms",
    GDPR: "gdpr",
    IS_PASSWORD_VISIBLE: "isPasswordVisible",
    IS_REPEAT_PASSWORD_VISIBLE: "isRepeatPasswordVisible",
  },

  FORM_TYPES: {
    REGISTER_FORM: "custom_register_form",
    EVENT_TICKETS: "event_tickets",
  },

  RESERVED_SPECIFIC_FIELD_NAMES: [
    "address_city",
    "address_email",
    "address_first_name",
    "address_last_name",
    "address_phone",
    "address_post_code",
    "address_street",
    "address_street_no",
    "invoice_city",
    "invoice_company_name",
    "invoice_nip",
    "invoice_post_code",
    "invoice_street",
    "invoice_street_no",
    "want_invoice",
  ],
});

export default FormBuilderConstants;
