<template>
  <section>
    <div class="field mb-1">
      <label class="label mb-1" :class="textColorClass">{{ placeholderText }}</label>
      <b-autocomplete
        v-model="query"
        ref="autocomplete"
        :data="filteredCountries"
        :placeholder="placeholderText"
        @select="addCountry"
        open-on-focus
      >
        <template slot-scope="props">
          <div class="media">
            <div class="has-flag media-left">
              <country-flag :code="props.option.code" v-if="props.option.code !== 'en'"></country-flag>
              <country-flag code="gb" v-else></country-flag>
            </div>
            <div class="media-content">
              <small>
                {{ props.option.name }}
              </small>
            </div>
          </div>
        </template>
        <template slot="empty">
          <div class="has-text-danger">
            {{ $t("edit_profile.country_error") }}
          </div>
        </template>
      </b-autocomplete>
    </div>
    <b-taglist>
      <div v-for="country in selectedCountries" v-bind:key="country.code">
        <b-tag class="mr-1" @close="removeCountry(country)" closable attached>
          <div class="is-flex">
            <div class="has-flag mr-1">
              <country-flag :code="country.code" v-if="country.code !== 'en'"></country-flag>
              <country-flag code="gb" v-else></country-flag>
            </div>
            <div>
              {{ country.name }}
            </div>
          </div>
        </b-tag>
      </div>
    </b-taglist>
  </section>
</template>

<script>
import { countries, languages } from "countries-list";
import CountryFlag from "@/web/components/shared/CountryFlag";

export default {
  name: "CountriesAutocomplete",

  props: {
    value: {
      type: String,
      default: "",
    },

    multiSelect: {
      type: Boolean,
      default: true,
    },

    selectLocale: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: false,
    },
  },

  components: { CountryFlag },

  data() {
    return {
      query: "",
    };
  },

  computed: {
    placeholderText() {
      if (this.label) {
        return this.label;
      } else {
        return this.$t("edit_profile.edit_profile_country_hint");
      }
    },

    textColorClass() {
      return "has-text-primary";
    },

    allCountries() {
      let keyValueArray = this.selectLocale ? Object.entries(languages) : Object.entries(countries);

      return keyValueArray
        .map(keyValue => {
          return { code: keyValue[0], ...keyValue[1] };
        })
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },

    filteredCountries() {
      if (this.query) {
        return this.allCountries.filter(country => {
          const queryLowerCase = this.query.toLowerCase();
          const countryLowerCase = country.name.toLowerCase();
          const countryNativeLowerCase = country.native.toLowerCase();
          return countryLowerCase.includes(queryLowerCase) || countryNativeLowerCase.includes(queryLowerCase);
        });
      } else {
        return this.allCountries;
      }
    },

    selectedCountryCodes() {
      if (this.value) {
        return this.value.split(",");
      } else {
        return [];
      }
    },

    selectedCountries() {
      return this.allCountries.filter(country => this.selectedCountryCodes.includes(country.code));
    },
  },

  methods: {
    addCountry(country) {
      if (this.multiSelect) {
        const selectedCountryCodes = this.selectedCountryCodes;
        if (!selectedCountryCodes.includes(country.code)) {
          selectedCountryCodes.push(country.code);
          const countryString = this.mapCountryCodesToString(selectedCountryCodes);
          this.$emit("input", countryString);
        }
      } else {
        this.$emit("input", country.code);
      }
    },

    removeCountry(countryToRemove) {
      const updatedCountryCodes = this.selectedCountryCodes.filter(countryCode => countryCode !== countryToRemove.code);
      const countryString = this.mapCountryCodesToString(updatedCountryCodes);
      this.$emit("input", countryString);
    },

    mapCountryCodesToString(countryCodes) {
      return countryCodes.join(",");
    },
  },

  watch: {
    multiSelect: {
      immediate: false,
      handler: function (newValue) {
        this.$emit("input", "");
      },
    },
  },
};
</script>

<style scoped lang="scss">
.has-flag img {
  display: block;
  width: 18px;
  height: auto;
}

.has-flag {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
