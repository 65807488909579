import { Model } from "@vuex-orm/core";

export default class ExhibitorTagModel extends Model {
  static entity = "exhibitor_tags";

  static fields() {
    return {
      id: this.attr(null),
      value: this.string(""),
      hex: this.string(""),
    };
  }
}
