import axios from "axios";
import Constants from "@/widgets/shared/constants";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST_URL,
  headers: {
    DeviceId: Constants.API_HEADER_DEVICE_ID,
    Accept: Constants.API_HEADER_ACCEPT_JSON,
  },
  validateStatus: status => status < 400,
});

let interceptor;

export function getAxiosInstance() {
  return axiosInstance;
}

export function storeInterceptor(interceptorInstance) {
  interceptor = interceptorInstance;
}

export function getInterceptor() {
  return interceptor;
}

export default axiosInstance;
