<template>
  <div :class="['card', 'with-hover', customClass || '']">
    <div class="card-content">
      <!-- Profile info -->
      <div class="media my-0">
        <div class="media-left">
          <profile-picture
            @click.native="openUserProfile()"
            :style="avatarStyle"
            class="is-60x60"
            :picture="picture"
            :initials="initials"
          ></profile-picture>
        </div>

        <div class="media-content">
          <p v-if="fullName" class="title">{{ fullName }}</p>
          <p v-if="description" class="subtitle">{{ description }}</p>
          <slot name="title"></slot>
        </div>

        <div class="media-right">
          <slot name="action"></slot>
        </div>
      </div>

      <!-- Additional details -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import UserProfileModal from "@/web/components/profile/UserProfileModal";

export default {
  components: {
    ProfilePicture,
  },

  props: ["picture", "firstName", "lastName", "description", "uuid", "customClass"],

  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },

    initials() {
      const a = ((this.firstName && this.firstName[0]) || "").toUpperCase();
      const b = ((this.lastName && this.lastName[0]) || "").toUpperCase();
      return `${a}${b}`;
    },

    avatarStyle() {
      if (this.uuid) {
        return {
          cursor: "pointer",
        };
      } else {
        return {};
      }
    },
  },

  methods: {
    openUserProfile() {
      if (this.uuid) {
        this.$buefy.modal.open({
          parent: this,
          component: UserProfileModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            userId: this.uuid,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: auto;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .media {
    align-items: center;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
</style>
