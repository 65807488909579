import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("errorModal", ["errorModalVisible", "errorModalProps"]),

    errorModalActive: {
      set(newValue) {
        if (!newValue) {
          this.closeErrorModal();
        }
      },

      get() {
        return this.errorModalVisible;
      },
    },
  },

  methods: {
    ...mapMutations("errorModal", ["closeErrorModal"]),
  },
};
