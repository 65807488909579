export function navScrollTo(hashOrId, behavior) {
  const id = normalizeId(hashOrId);
  const target = document.getElementById(id);

  if (id && target) {
    const top = target.offsetTop - 100;
    document.documentElement.scrollTo({ top, behavior });
  }
}

function normalizeId(hashOrId) {
  return hashOrId[0] === "#" ? hashOrId.substr(1) : hashOrId;
}

export default navScrollTo;
