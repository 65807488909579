export const PaymentConstants = Object.freeze({
  // Gateways
  GATEWAY_FREE_TICKET_ID: "free",

  GATEWAY_P24_ID: "p24",
  GATEWAY_P24_URL: process.env.VUE_APP_GATEWAY_P24_URL,

  GATEWAY_STRIPE_ID: "stripe",
  GATEWAY_STRIPE_KEY: process.env.VUE_APP_GATEWAY_STRIPE_KEY,

  GATEWAY_PAYU_ID: "payu",

  GATEWAY_BLUE_MEDIA_ID: "bluemedia",
  BLUE_MEDIA_GATEWAYS: {
    // TODO: get correct ids
    CARD: 0,
    GOOGLE_PAY: 1,
    APPLE_PAY: 2,
    BLIK: 509,
  },

  TRANSACTION_STATUSES: {
    PENDING: "PENDING",
    WAITING: "WAITING_FOR_CONFIRMATION",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED",
  },

  PURCHASE_SUMMARY_ROW_FIELDS: {
    TICKET: "ticket",
    DISCOUNT: "discount",
    DISCOUNTED_TICKETS_QUANTITY: "discounted_tickets_quantity",
    QUANTITY: "quantity",
    MARKETING_DISCOUNT: "marketing_discount",
    ADDON_ROW: "addon_row",
    ADDON_MODEL: "addon_model",
    ADDON_QUANTITY: "addon_quantity",
    BULK_DISCOUNT: "bulk_discount",
  },

  GOOGLE_PAY: {
    GOOGLE_PAY_ALLOWED_CARD_NETWORKS: ["MASTERCARD", "VISA"],

    GOOGLE_PAY_ALLOWED_CARD_AUTH_METHODS: ["PAN_ONLY", "CRYPTOGRAM_3DS"],

    GOOGLE_PAY_BASE_REQUEST: {
      apiVersion: 2,
      apiVersionMinor: 0,
    },

    BASE_CARD_PAYMENENT_METHOD: {
      type: "CARD",
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"],
      },
    },

    GOOGLE_PAY_TOKENIZATION_SPECIFICATION: {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "bluemedia",
        gatewayMerchantId: null,
      },
    },

  },
});

export default PaymentConstants;
